import React, { Component } from 'react'
import { Table } from 'reactstrap';
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helmet } from "react-helmet";
import { cap,pw } from '../params'
import { Form, Row, Col, Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import '../style/gold2.css'
import path from '../assets/img/gold2/path1.svg'
import img1 from '../assets/img/gold2/wed1.jpg'
import img2 from '../assets/img/gold2/wed2.jpg'
import img3 from '../assets/img/gold2/wed3.jpg'
import img4 from '../assets/img/gold2/wed4.jpg'
import music from '../assets/music/music2.aac'
import logoig from '../assets/img/gold2/logoig.svg'
import path3 from '../assets/img/gold1/path3.svg'
import groom from '../assets/img/gold1/rhobby.png'
import bride from '../assets/img/gold1/rovita.png'
import path2 from '../assets/img/gold1/path2.svg'
import bunga6 from '../assets/img/bunga6.png'
import logo from '../assets/img/logo.ico'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import post from '../params/post'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain=""
let id = 'echa-ivan'
let inisial_co = 'Ivan'
let inisial_ce = 'Echa'
let lengkap_co = ' Ivan Pradibta Sebayang'
let lengkap_ce = 'Theresia Monica br. Ginting'
let bapak_co = 'Bpk. Sima Sebayang'
let ibu_co = 'Ibu Jani br. Tarigan'
let bapak_ce = "Bpk. Edi Suranta Ginting"
let ibu_ce = "Ibu Veranita br. Pandia"
let ig_co = "ivan_pradibta"
let ig_ce = "theresiaginting"

let foto_ce = pw(id,"ce.jpg")
let foto_co = pw(id,"co.jpg")
let waktunikah = "12/20/2020"

let modal = pw(id,"modal.jpg")
let openlogo = pw(id,"logo.png")

let gmaps = "https://maps.app.goo.gl/N3Cne96FRkSN5wMFA"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MHVzMTZka3VlaGJmcXBtYjQxY2xkcXVsNXAgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.661085105068!2d114.00215231477767!3d-7.719464994435708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zN8KwNDMnMTAuMSJTIDExNMKwMDAnMTUuNiJF!5e0!3m2!1sid!2sid!4v1607223099284!5m2!1sid!2sid"


export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      clicked: 0
    }
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
        err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
        err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
        confirmAlert({
            message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let send = await post(
                            ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
                        )
                        if (send.status == 200) {
                            this.setState({ submitted: true })
                            localStorage.setItem('pesan', this.pesan.current.value)
                            this.nama.current.value = ''
                            this.pesan.current.value = ''
                        }else{
                            err.push('Koneksi Gagal')
                        }

                        }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    } else {
        this.setState({ err: err, submitted: false })
    }
}
  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 1000
    });
    let acara = [[
      'Pemberkatan',
      '20 Februari 2021',
      <><div className="w-100 text-center">
          Wisma Joglo<br/> Jl. Resort Dago Pakar No.19, Dago, Bandung
          </div></>,
      '08.00 WIB - Selesai'
    ],
    [
      'Acara Adat',
      '20 Februari 2021',
      <><div className="w-100 text-center">
          Wisma Joglo<br/> Jl. Resort Dago Pakar No.19, Dago, Bandung
          </div></>,
      '11.00 WIB - Selesai'
    ]
    ]
    this.setState({ acara: acara })
    var countDownDate = new Date("02/20/2021").getTime();

    // Update the count down every 1 second
    var x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })

    }, 1000);

  }
  play = () => {
    
    var snd = new Audio(pw(id,"music.mp3"));
    snd.type = 'audio/mp3';
    snd.play();
    window.scrollTo({
      top: this.myRef.current.offsetTop,
      behavior: 'smooth'
    })
    this.setState({ hide: false })
    setTimeout(()=>{
      var elmnt = document.getElementById('sectiongold24');
      elmnt.scrollIntoView();
    },1000)
    setTimeout(()=>{
      AOS.refresh()
    },1100)
    
  }
  googleCalendar = () => {
    window.open(`https://calendar.google.com/calendar/r/eventedit?
text=Event%20Title
&details=Pernikahan%20Willy%20dan%20Jessica
&dates=20201010T100000/20201010T113000
&ctz=Indonesia%2FEast_Java&title=Pernikahan%20Willy%20dan%20Jessica
    `, '_blank')
  }
  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }

  render() {
    let { clicked, acara, days, hours, minutes, seconds, hide,hadir,err,submitted } = this.state
    let story = [
      ['Modi sit est',
        'Quis excepturi porro totam sint earum quo nulla perspiciatis eius.',
        img1],
      ['Modi sit est',
        'Quis excepturi porro totam sint earum quo nulla perspiciatis eius.',
        img2],
      ['Modi sit est',
        'Quis excepturi porro totam sint earum quo nulla perspiciatis eius.',
        img3],
      ['Modi sit est',
        'Quis excepturi porro totam sint earum quo nulla perspiciatis eius.',
        img4]
    ]
    console.log(clicked)
    return (
      <>
        <Helm
          title='Undanganku - Gold 2'
          desc="Undangan berbasis online harga mulai 100 ribuan, responsive, interaktif, dan modern"
          logo={logo}
        />
        <div id='gold2'>
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw'
          }}>
            {
            this.useQuery().get('x')=="x"?(<Float/>):false
          }

            <div className="jumbotrongold2 jumbotron-fluid mb-0 w-100" id='sectiongold21' style={{
              overflow: 'hidden', maxWidth: '100vw'
            }}>
              <div className={`path w-100 ${hide ? 'hide' : 'show'}`}>
                <img src={path} className='img-fluid w-100' />
              </div>

              <div className="container" >

                <div className='position' data-aos="flip-left" data-aos-duration="1000" >
                  <Item><h1>You Are Invited!</h1>
                  </Item>
                  <Item><h2>The Wedding of</h2>
                  </Item>
                </div>
                <div
                  className='mt-3 nama'
                  data-aos="zoom-in" data-aos-duration="1000"
                >
                  <Item>
                    {inisial_ce}
                      </Item>
                  <Item>
                    &
            </Item>
                  <Item>
                    {inisial_co}
            </Item>
                </div>
                <div className='tombol'>
                  <Item>
                    <button
                      onClick={() => {
                        this.play()
                      }}
                      style={{
                      }}
                    >Open Invitation</button>
                  </Item>
                </div>
              </div>
            </div>
            <div className={hide?'d-none':'d-block'}>
              

            <Container id='sectiongold24' className='pb-md-5' ref={this.myRef}>
              <div className='path w-100' >
                <img src={path3} className='img-fluid w-100' />
              </div>
              <div className='pt-5 pb-5'>

                <Item>
                  <Col xs={4} lg={1}>
                    <img src={path2} className='img-fluid w-100' data-aos="fade-left" data-aos-duration="1000" />
                  </Col>
                </Item>
                <Item>
                  <p className='text-center col-md-8 quotes pt-3 pb-md-5' data-aos="fade-left"
                    data-aos-duration="1000">
                    “Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.”<br/><br/>
(Matius 19:6)


              </p>
                </Item>

              </div>
            </Container>
            <Container id='mempelai'>
              <Item>
                <Col xs={12} md={8}>
                  <div className='opening' data-aos="zoom-in">
                  “Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan.”
<br/><br/>
Dengan kasih karunia Tuhan dan segala kerendahan hati, izinkan kami memohon doa restu untuk pernikahan putra-putri kami:

                </div>
                  <Item>
                    <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1>
                            The Bride
                </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={groom} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1>
                            {lengkap_ce}
            </h1>
                        </Item>
                        <Item>
                          <p className='text-center'>
                            The Daughter of<br />
                        {bapak_ce}<br />
and<br />
{ibu_ce}<br />
                          </p>
                        </Item>
                        <Item>
                          
                            <img src={logoig}
                            onClick={()=>{
                                window.location.href='https://instagram.com/'+ig_ce
                            }}
                             className='img-fluid w-100 p-0 btn' 
                             style={{width:'35px',height:'35px'}} />
                        
                        </Item>
                      </div>
                    </div>
                    <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1>
                            The Groom
            </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={bride} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 >
                            {lengkap_co}
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center'>
                            The Son of<br />
                            {bapak_co}<br />
and<br />
{ibu_co}<br />
                          </p>
                        </Item>
                        <Item>
                        <img src={logoig}
                            onClick={()=>{
                                window.location.href=`https://instagram.com/${ig_co}`
                            }}
                             className='img-fluid w-100 p-0 btn' style={{width:'35px',height:'35px'}} />
                        
                        </Item>
                      </div>
                    </div>
                  </Item>
                </Col>
              </Item>
            </Container>


            <Container id='sectiongold27'>
              <div className='pt-3 ml-3 mr-3'>
                <Item>
                  <Col xs={4} lg={2}>
                    <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                  </Col>
                </Item>
                <Item>
                  <Col xs={12} md={6} className='katok'>

                    {
                      acara.map((val, i) => {
                        return (
                          <div data-aos={`fade-${i % 2 == 0 ? 'left' : 'right'}`} data-aos-duration="1000">
                            <Item>
                              <h1>
                                {val[0]}
                              </h1>
                            </Item>
                            <Item>
                              <p className='bold italic'>
                                {val[1]}
                              </p>
                            </Item>
                            <Item>
                              <p className='italic'>
                                {val[2]}
                              </p>
                            </Item>
                            <Item>
                              <p className='mt-2'>
                                {val[3]}
                              </p>
                            </Item>
                          </div>
                        )
                      })
                    }
                  </Col>
                </Item>
                <Item>
                  <div data-aos="zoom-in" data-aos-duration="1000" className='btn col-6 col-lg-4 mt-3 button text-center smaller' onClick={() => {
                    window.location.href=""
                  }} >
                    Link Youtube Acara Pemberkatan
                </div>
                </Item>
                <Item>
                  <div data-aos="zoom-in" data-aos-duration="1000" className='btn col-6 col-lg-4 mt-3 button text-center smaller' onClick={() => {
                    window.location.href=""
                  }} >
                   Link Youtube Acara Adat
                </div>
                </Item>
                <Item>
                  <div data-aos="zoom-in" data-aos-duration="1000" className='btn col-6 col-lg-4 mt-3 button text-center smaller' onClick={() => {
                    window.location.href="https://calendar.google.com/event?action=TEMPLATE&tmeid=NWszZWo3YWNxZXBmZWtsNmxtbzI3N244a3AgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com"
                  }} >
                    Add to Google Calendar
                </div>
                </Item>
                <Item>
                  <div className='col-8 pt-3'>
                    <p data-aos="zoom-in" data-aos-duration="1000" className='italic red text-center'> 
                    Dikarenakan adanya wabah COVID-19 maka acara pernikahan ini hanya akan dihadiri oleh keluarga inti dari kedua mempelai. Besar harapan kami, Bapak/ Ibu dapat meluangkan waktu untuk mengikuti prosesi ini secara virtual melalui link Youtube di atas. Kami mohon doa restu dari Bapak/ Ibu sekalian untuk pernikahan putra-putri kami.
                    </p>
                  </div>
                </Item>
                <Item>
                  <div data-aos="fade-left" data-aos-duration="1000" className='col-10 col-lg-8 kotak'>
                    <Item>
                      <div className='item'>
                        <Item>
                          <div>
                            {days}
                          </div>
                        </Item>
                        <Item>
                          <span>
                            Days
                      </span>
                        </Item>
                      </div>
                      <div className='dot'>:</div>
                      <div className='item'>
                        <Item>
                          <div>
                            {hours}
                          </div>
                        </Item>
                        <Item>
                          <span>
                            Hours
                      </span>
                        </Item>
                      </div>
                      <div className='dot'>:</div>
                      <div className='item'>
                        <Item>
                          <div >
                            {minutes}
                          </div>
                        </Item>
                        <Item>
                          <span>
                            Mins
                      </span>
                        </Item>
                      </div>
                      <div className='dot' >:</div>
                      <div className='item'>
                        <Item>
                          <div>
                            {seconds}
                          </div>
                        </Item>
                        <Item>
                          <span>
                            Secs
                      </span>
                        </Item>
                      </div>
                    </Item>

                  </div>
                </Item>
              </div></Container>
            <Container className='mt-3' data-aos="fade-right" data-aos-duration="1000">
              <Slider />
            </Container>
            <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                        

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor:'#77411A' , color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>

            <Container id='sectiongold210' >
              <div className='path w-100 '>
                <img src={path3} className='img-fluid w-100 ' />
              </div>
            </Container>
            <Container fluid style={{backgroundColor:'white'}}>
                <Foot ig={logoig} dark/>
            </Container>
            
            </div>
          </div>
        </div>
      </>


    )
  }
}